import { FillerMatrixLookup, MenuLookup, WPSLookup } from "../../../common/lookup";
import { PageButton, PageControls } from "../../../common/PageControls";
import { Process } from "../types";
import { SaveChangesDialog } from "../../../common/Dialogs";
import { useContext } from "react";
import { useGetProcesses, useSave } from "../queries";
import { useModal } from "hooks";
import { WelderContext } from "../reducer";
import { String } from "@utils";

export function MainInterface() {
  const { state, updateState } = useContext(WelderContext);
  const { isLoading } = useGetProcesses(state, updateState);

  const save = useSave();
  const modal = useModal();

  if (isLoading) {
    return <p>Loading welds...</p>;
  }

  function isGroupRow(row: Process) {
    const index = state.processes.indexOf(row);
    return index === 0 || row.weldId !== state.processes[index - 1].weldId;
  }

  function handleFieldChanged(row: Process, field: string, value: string | boolean) {
    const processes = [...state.processes];
    const index = processes.indexOf(row);
    processes[index] = { ...processes[index], [field]: value };

    updateState({
      type: "Processes",
      payload: processes,
    });
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover text-center text-nowrap">
          <thead>
            <tr>
              <th>Weld No.</th>
              <th>Process</th>
              <th>WPS</th>
              <th>Filler</th>
              <th>Heat No.</th>
              <th>Plant No.</th>
              <th>Made</th>
              <th>Complete</th>
              <th>Visual</th>
            </tr>
          </thead>
          <tbody>
            {state.processes.map((row, index) => (
              <tr key={index}>
                <th>{isGroupRow(row) && row.weldNumber}</th>
                <td>
                  <MenuLookup
                    id="process"
                    value={row.process}
                    menu="WeldingProcess(US)"
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                  />
                </td>
                <td>
                  <WPSLookup
                    id="wpsNumber"
                    process={row.process}
                    value={row.wpsNumber}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <FillerMatrixLookup
                    id="filler"
                    process={row.process}
                    wpsNumber={row.wpsNumber}
                    value={row.filler}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    id="heatNumber"
                    className="form-control"
                    value={row.heatNumber}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    id="plantNumber"
                    className="form-control"
                    value={row.plantNumber}
                    onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process)}
                  />
                </td>
                <td>
                  <div className="form-check form-switch d-flex justify-content-center">
                    <input
                      type="checkbox"
                      id="made"
                      className="form-check-input"
                      checked={row.made}
                      onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                      disabled={String.isNullOrWhiteSpace(row.wpsNumber) || String.isNullOrWhiteSpace(row.filler)}
                    />
                  </div>
                </td>
                <td>
                  {isGroupRow(row) && (
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input
                        type="checkbox"
                        id="completed"
                        className="form-check-input"
                        checked={row.completed}
                        onChange={(e) => handleFieldChanged(row, e.currentTarget.id, e.currentTarget.checked)}
                        disabled={state.processes.some((x) => x.weldId === row.weldId && x.made !== true)}
                      />
                    </div>
                  )}
                </td>
                <td>
                  {isGroupRow(row) && (
                    <div className="form-check form-switch d-flex justify-content-center">
                      <input className="form-check-input" type="checkbox" disabled={row.completed !== true} />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageControls showBack>
        <PageButton
          onClick={() => modal.show(<SaveChangesDialog onAccept={() => save.mutate(state.processes)} />)}
          loading={save.isLoading}
        >
          Save and Finish
        </PageButton>
      </PageControls>
    </>
  );
}
