import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FillerMatrixLookup, HeatNumberLookup, MenuLookup, WPSLookup } from "../../../common/lookup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageButton, PageControls } from "../../../common/PageControls";
import { SaveChangesDialog } from "../../../common/Dialogs";
import { String } from "@utils";
import { useContext } from "react";
import { useGetProcesses, useSave } from "../queries";
import { useModal } from "@hooks";
import { WelderContext } from "../reducer";
import { WelderRow } from "@entities/hitachi";

export const MainInterface = () => {
  const { state, updateState } = useContext(WelderContext);
  const { isLoading } = useGetProcesses(state, updateState);

  const save = useSave();
  const modal = useModal();

  if (isLoading) {
    return <p>Loading welds...</p>;
  }

  function isGroupRow(row: WelderRow) {
    const index = state.processes.indexOf(row);
    return index === 0 || row.weldId !== state.processes[index - 1].weldId;
  }

  function handleSave(redirect: boolean) {
    save.mutate({ processes: state.processes, redirect });
  }

  function handleFieldChanged(index: number, field: string, value: string | boolean) {
    const processes = [...state.processes];
    processes[index] = { ...processes[index], [field]: value };
    updateState({
      type: "Processes",
      payload: processes,
    });
  }

  function handleCopy(field: string, value: string) {
    updateState({
      type: "Processes",
      payload: state.processes.map((process) => {
        if (!process.locked) {
          return { ...process, [field]: value };
        }
        return process;
      }),
    });
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover text-center text-nowrap">
          <thead>
            <tr>
              <th>Weld No.</th>
              <th>Process</th>
              <th></th>
              <th>HIT.WPS</th>
              <th></th>
              <th>Filler</th>
              <th></th>
              <th>Lot No.</th>
              <th></th>
              <th>NAY Weld Asset No.</th>
              <th></th>
              <th>Unfinished</th>
              <th>Finished</th>
            </tr>
          </thead>
          <tbody>
            {state.processes.map((row, index) => (
              <tr key={index}>
                <th>{isGroupRow(row) && row.weldNumber}</th>
                <td>
                  <MenuLookup
                    menu="WeldingProcess(EN)"
                    value={row.process}
                    disabled={row.locked}
                    onChange={(e) => handleFieldChanged(index, "process", e.currentTarget.value)}
                  />
                </td>
                <td className="align-middle p-0">
                  {index === 0 && (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size="sm"
                      type="button"
                      title="Copy"
                      onClick={() => handleCopy("process", row.process)}
                    />
                  )}
                </td>
                <td>
                  <WPSLookup
                    process={row.process}
                    value={row.wpsNumber}
                    onChange={(e) => handleFieldChanged(index, "wpsNumber", e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process) || row.locked}
                  />
                </td>
                <td className="align-middle p-0">
                  {index === 0 && (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size="sm"
                      type="button"
                      title="Copy"
                      onClick={() => handleCopy("wpsNumber", row.wpsNumber)}
                    />
                  )}
                </td>
                <td>
                  <FillerMatrixLookup
                    process={row.process}
                    wpsNumber={row.wpsNumber}
                    value={row.filler}
                    onChange={(e) => handleFieldChanged(index, "filler", e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process) || row.locked}
                  />
                </td>
                <td className="align-middle p-0">
                  {index === 0 && (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size="sm"
                      type="button"
                      title="Copy"
                      onClick={() => handleCopy("filler", row.filler)}
                    />
                  )}
                </td>
                <td>
                  <HeatNumberLookup
                    classification={row.filler}
                    value={row.lotNumber}
                    onChange={(e) => handleFieldChanged(index, "lotNumber", e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process) || row.locked}
                  />
                </td>
                <td className="align-middle p-0">
                  {index === 0 && (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size="sm"
                      type="button"
                      title="Copy"
                      onClick={() => handleCopy("lotNumber", row.lotNumber)}
                    />
                  )}
                </td>
                <td>
                  <MenuLookup
                    menu="AssetNumber"
                    value={row.assetNumber ?? ""}
                    onChange={(e) => handleFieldChanged(index, "assetNumber", e.currentTarget.value)}
                    disabled={String.isNullOrWhiteSpace(row.process) || row.locked}
                  />
                </td>
                <td className="align-middle p-0">
                  {index === 0 && (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size="sm"
                      type="button"
                      title="Copy"
                      onClick={() => handleCopy("assetNumber", row.assetNumber)}
                    />
                  )}
                </td>
                <td>
                  <div className="form-check form-switch d-flex justify-content-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={row.unfinished}
                      onChange={(e) => handleFieldChanged(index, "unfinished", e.currentTarget.checked)}
                      disabled={row.finished || row.locked}
                    />
                  </div>
                </td>
                <td>
                  <div className="form-check form-switch d-flex justify-content-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={row.finished}
                      onChange={(e) => handleFieldChanged(index, "finished", e.currentTarget.checked)}
                      disabled={row.unfinished || row.locked}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PageControls showBack allowBack={!save.isLoading}>
        <PageButton onClick={() => handleSave(false)} loading={save.isLoading}>
          Save
        </PageButton>
        <PageButton
          onClick={() => modal.show(<SaveChangesDialog onAccept={() => handleSave(true)} />)}
          loading={save.isLoading}
        >
          Save and Finish
        </PageButton>
      </PageControls>
    </>
  );
};
