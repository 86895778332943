import { DateTime, ServiceClient } from "@utils";
import { ErrorBoundary } from "react-error-boundary";
import { Header } from "./Header";
import { Outlet, useLocation } from "react-router";
import { SideMenu } from "./SideMenu";
import { useVersion } from "@queries/version.queries";

export function BaseLayout() {
  const version = useVersion();
  const location = useLocation();

  const showSideMenu = ServiceClient.isAuthenticated() && window.location.href.indexOf("/login") === -1;

  return (
    <>
      <Header />
      {showSideMenu && <SideMenu />}

      <div className="has-nav-container">
        <div id="body" className="main-row">
          <ErrorBoundary fallback={<p>An unknown error occurred.</p>} key={location.pathname}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>

      <footer className="d-flex flex-wrap justify-content-between p-4">
        <div>
          <a href="https://www.twisoftware.com" title="TWI Software">
            &copy; {new Date().getFullYear()} TWI Software, All rights reserved
          </a>
        </div>
        <div>
          Version: {version.data?.waypointVersion} ({DateTime.getLocalDate(version.data?.waypointBuildDate)})
        </div>
      </footer>
    </>
  );
}
