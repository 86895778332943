import { api } from "@api";
import { Weld } from "@entities/Weld";

export function getWeld(weldId: string) {
  return api.get<Weld>(`welds/${weldId}`);
}

export function addAttachment(weldId: string, attachment: File) {
  const formData = new FormData();
  formData.append("file", attachment);

  return api.post(`welds/${weldId}/attachments`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
