import { DateTime } from "@utils";
import { PageControls } from "../../../common/PageControls";
import { useConfiguration } from "@queries/configuration.queries";
import { useContext } from "react";
import { useGetWeldList } from "../queries";
import { WelderContext } from "../reducer";
import { WelderWeld } from "@entities/hitachi";
import { WeldSelectionFilters } from "./WeldSelectionFilters";

export function WeldSelection() {
  const { state, updateState } = useContext(WelderContext);

  const config = useConfiguration();
  const welds = useGetWeldList(state.filters, state.datasheet);

  if (welds.isLoading) {
    return <p>Loading welds...</p>;
  }

  function handleWeldSelected(e: React.FormEvent<HTMLInputElement>, weld: WelderWeld) {
    const allowMultiple = config.data?.rbiWelderMultipleWelds;
    if (e.currentTarget.checked) {
      updateState({
        type: "Welds",
        payload: allowMultiple ? [...state.welds, weld] : [weld],
      });
    } else {
      updateState({
        type: "Welds",
        payload: state.welds.filter((x) => x.weldId !== weld.weldId),
      });
    }
  }

  return (
    <>
      <div className="text-secondary mb-4">
        {state.project?.name} / {state.subProject?.name} / {state.datasheet?.datasheetName}
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-hover text-center text-nowrap">
          <thead>
            <tr>
              <th>Weld No.</th>
              <th>Joint Type</th>
              <th>WPS Number</th>
              <th>Insp. Class</th>
              <th>Competency</th>
              <th>Made</th>
              {welds.data?.some((x) => x.drawingId) && <th>Weld Map</th>}
              <th>
                {config.data?.rbiWelderMultipleWelds && welds.data && welds.data.length > 1 && (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={state.welds.length === welds.data.length}
                    onChange={(e) => {
                      updateState({
                        type: "Welds",
                        payload: e.currentTarget.checked ? welds.data : [],
                      });
                    }}
                  />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {welds.data?.map((weld, index) => (
              <tr key={index}>
                <th>{weld.weldNumber}</th>
                <td>{weld.jointType}</td>
                <td>{weld.wpsNumber}</td>
                <td>{weld.inspectionClass}</td>
                <td>{weld.competency}</td>
                <td>{DateTime.getLocalDate(weld.madeDate)}</td>
                {welds.data?.some((x) => x.drawingId) && (
                  <td>
                    {weld.drawingId && (
                      <a
                        href={`drawing/${weld.drawingId}?weldId=${weld.weldId}`}
                        target="_blank"
                        className="link-primary"
                      >
                        View
                      </a>
                    )}
                  </td>
                )}
                <td>
                  <input
                    type={config.data?.rbiWelderMultipleWelds ? "checkbox" : "radio"}
                    className="form-check-input"
                    checked={state.welds.some((e) => e.weldId === weld.weldId)}
                    onChange={(e) => handleWeldSelected(e, weld)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <WeldSelectionFilters state={state} updateState={updateState} />
        <PageControls showBack showNext allowNext={state.welds.length > 0} />
      </div>
    </>
  );
}
