import * as api from "api/hitachi/rbi";
import { addAttachment } from "api/welds";
import { Datasheet } from "@entities";
import { InspectorContext } from "./reducer";
import { InspectorRow } from "@entities/hitachi";
import { Page } from "@utils";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { WeldListFilters } from "./types";

export function useGetWeldList(filters: WeldListFilters, datasheet?: Datasheet) {
  const datasheetId = datasheet?.datasheetId ?? "";
  return useQuery({
    enabled: Boolean(datasheet),
    queryKey: ["rbi/inspector/welds", { datasheetId, filters }],
    queryFn: () => api.getInspectorWeldList(datasheetId, filters),
  });
}

export function useGetInspections(weldIds: string[]) {
  const { updateState } = useContext(InspectorContext);
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["rbi/welder/inspections", { weldIds }],
    queryFn: () => api.getInspectorRows(weldIds),
    onSuccess: (data) => {
      updateState({ type: "Inspections", payload: data });
    },
  });
}

export function useSave() {
  return useMutation(async (args: { inspections: InspectorRow[]; redirect: boolean }) => {
    await api.saveInspectorRows(args.inspections);
    await saveAttachments(args.inspections);
    if (args.redirect) {
      Page.redirect("rbi/hitachi/inspector");
    }
  });
}

function saveAttachments(inspections: InspectorRow[]) {
  return Promise.all(
    inspections.map(async (row) => {
      if (row.attachment) {
        await addAttachment(row.weldId, row.attachment);
      }
    })
  );
}
