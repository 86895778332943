import { api } from "@api";
import { InspectorRow, InspectorWeld, WelderRow, WelderWeld } from "@entities/hitachi";

export type WeldListFilters = {
  onlyMyWelds: boolean;
  onlyWeldsNotMade: boolean;
  onlyWeldsFromSequence: boolean;
  onlyRepairWelds: boolean;
  onlyWeldsReadyForNdt: boolean;
};

export function getWelderWeldList(datasheetId: string, filters: Partial<WeldListFilters>) {
  const params = new URLSearchParams({
    datasheetId,
    ...Object.fromEntries(Object.entries(filters)),
  });

  return api.get<WelderWeld[]>(`hitachi/rbi/welder/welds?${params.toString()}`);
}

export function getWelderRows(weldIds: string[]) {
  return api.post<WelderRow[]>("hitachi/rbi/welder/processes", weldIds);
}

export function saveWelderRows(processes: WelderRow[]) {
  return api.post("hitachi/rbi/welder", processes);
}

export function getInspectorWeldList(datasheetId: string, filters: Partial<WeldListFilters>) {
  const params = new URLSearchParams({
    datasheetId,
    ...Object.fromEntries(Object.entries(filters)),
  });

  return api.get<InspectorWeld[]>(`hitachi/rbi/inspector/welds?${params.toString()}`);
}

export function getInspectorRows(weldIds: string[]) {
  return api.post<InspectorRow[]>("hitachi/rbi/inspector/inspections", weldIds);
}

export function saveInspectorRows(processes: InspectorRow[]) {
  return api.post("hitachi/rbi/inspector", processes);
}
