import * as api from "api/hitachi/rbi";
import { Datasheet } from "@entities";
import { Dispatch } from "react";
import { Page } from "@utils";
import { RbiAction } from "./reducer";
import { useMutation, useQuery } from "react-query";
import { WelderRow } from "@entities/hitachi";
import { WelderState, WeldListFilters } from "./types";

export function useGetWeldList(filters: WeldListFilters, datasheet?: Datasheet) {
  const datasheetId = datasheet?.datasheetId ?? "";
  return useQuery({
    enabled: Boolean(datasheet),
    queryKey: ["rbi/welder/welds", { datasheetId, filters }],
    queryFn: () => api.getWelderWeldList(datasheetId, filters),
  });
}

export function useGetProcesses(state: WelderState, updateState: Dispatch<RbiAction>) {
  const weldIds = state.welds.map((x) => x.weldId);
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["rbi/welder/processes", { weldIds }],
    queryFn: () => api.getWelderRows(weldIds),
    onSuccess: (data) => {
      updateState({ type: "Processes", payload: data });
    },
  });
}

export function useSave() {
  return useMutation(async (args: { processes: WelderRow[]; redirect: boolean }) => {
    await api.saveWelderRows(args.processes);
    if (args.redirect) {
      Page.redirect("rbi/hitachi/welder");
    }
  });
}
