import { Datasheet, Project, SubProject } from "@entities";
import { InspectorRow, InspectorWeld } from "@entities/hitachi";

export type Ndt = {
  discipline: string;
};

export type InspectorState = {
  project?: Project;
  subProject?: SubProject;
  datasheet?: Datasheet;
  welds: InspectorWeld[];
  inspections: InspectorRow[];
  filters: WeldListFilters;
};

export type WeldListFilters = {
  onlyWeldsReadyForNdt: boolean;
  onlyRepairWelds: boolean;
  onlyWeldsFromSequence: boolean;
};

export const defaultFilters: WeldListFilters = {
  onlyWeldsReadyForNdt: true,
  onlyRepairWelds: false,
  onlyWeldsFromSequence: false,
};

export const defaultState: InspectorState = {
  welds: [],
  inspections: [],
  filters: defaultFilters,
};

export type StateType = "Project" | "SubProject" | "Datasheet" | "Welds" | "Inspections" | "Filters";
