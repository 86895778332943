import { Datasheet, Project, SubProject } from "@entities";
import { WelderRow, WelderWeld } from "@entities/hitachi";

export type WelderState = {
  project?: Project;
  subProject?: SubProject;
  datasheet?: Datasheet;
  welds: WelderWeld[];
  processes: WelderRow[];
  filters: WeldListFilters;
};

export type WeldListFilters = {
  onlyMyWelds: boolean;
  onlyWeldsNotMade: boolean;
  onlyWeldsFromSequence: boolean;
  onlyRepairWelds: boolean;
};

export const defaultFilters: WeldListFilters = {
  onlyMyWelds: false,
  onlyWeldsNotMade: true,
  onlyWeldsFromSequence: false,
  onlyRepairWelds: false,
};

export const defaultState: WelderState = {
  welds: [],
  processes: [],
  filters: defaultFilters,
};

export type StateType = "Project" | "SubProject" | "Datasheet" | "Welds" | "Processes" | "Filters";
