import { InputHTMLAttributes } from "react";
import { useCoordinatorMenu } from "@queries/menu.queries";

type MenuLookupProps = InputHTMLAttributes<HTMLInputElement> & {
  menu: string;
};

export function MenuLookup(props: MenuLookupProps) {
  const lookup = useCoordinatorMenu(props.menu);

  return (
    <>
      <input type="text" className="form-select" list={props.menu} {...props} />
      <datalist id={props.menu}>
        {lookup.data?.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    </>
  );
}
